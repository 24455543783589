<template>
    <div class="conacts_info" v-if="customerContactList">
        <div class="conacts_info_item">
            <label>联系人</label>
            <p>{{ customerContactList.contactName || '- -' }}</p>
        </div>
        <div class="conacts_info_item">
            <label>联系方式</label>
            <p v-if="customerContactList.customerContactWayDOList">
                <span
                    v-for="(item,
                    index) in customerContactList.customerContactWayDOList"
                    :key="index"
                >
                    <i>{{ $tableDataHandle.contactWay(item) }}</i>
                    {{ item.contactContent }}
                </span>
            </p>
            <p v-else>- -</p>
        </div>
        <div class="conacts_info_item">
            <label>联系人标签</label>
            <p>
                <em
                    v-for="item in customerContactTagList"
                    :key="item.id"
                    :class="item.category == 0 ? 'blue' : 'orange'"
                >
                    {{ item.tagName }}</em
                >
            </p>
        </div>
        <div v-show="tag">
            <div class="conacts_info_item">
                <div class="left_item">
                    <label>性别</label>
                    <p>{{ customerContactList.sex == 1 ? '男' : '女' }}</p>
                </div>
                <div class="right_item">
                    <label>部门</label>
                    <p>{{ customerContactList.department || '- -' }}</p>
                </div>
            </div>
            <div class="conacts_info_item">
                <div class="left_item">
                    <label>职务</label>
                    <p>{{ customerContactList.duty || '- -' }}</p>
                </div>
                <div class="right_item">
                    <label>公司</label>
                    <p>{{ customerContactList.companyName || '- -' }}</p>
                </div>
            </div>
            <div class="conacts_info_item">
                <label>生日</label>
                <p>{{ customerContactList.birthday || '- -' }}</p>
            </div>
            <div class="conacts_info_item">
                <label>备注</label>
                <p>{{ customerContactList.remark || '- -' }}</p>
            </div>
        </div>
        <div class="btn">
            <span @click="changeStatus"
                >{{ !tag ? '展开' : '收起' }}
                <i
                    :class="!tag ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                ></i>
            </span>
        </div>
    </div>
</template>

<script>
import { relationList } from '@/api/user/label.js';
export default {
    name: '',
    props: ['customerContactList'],
    data() {
        return {
            tag: false,
            customerContactTagList: [],
        };
    },
    components: {},
    created() {},
    mounted() {
        this.relationList();
    },
    methods: {
        // 修改联系人信息展示状态
        changeStatus() {
            this.tag = !this.tag;
        },
        // 获取所属标签
        relationList() {
            let data = {
                param: {
                    bandingTableType: 4,
                    bandingId: this.customerContactList.id,
                },
            };
            relationList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.customerContactTagList = res.data.list;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.conacts_info {
    font-size: 14px;
    color: #333;
    margin-bottom: 15px;
    > .conacts_info_item:first-child {
        position: relative;
    }
    > .conacts_info_item:first-child:after {
        content: '';
        position: absolute;
        left: -8px;
        top: 50%;
        transform: translateY(-50%);
        width: 3px;
        height: 14px;
        background: #2370eb;
        border-radius: 1px;
    }
    .conacts_info_item {
        display: flex;
        margin-bottom: 15px;
        justify-content: space-between;
        label {
            width: 75px;
            font-weight: 600;
        }
        p {
            flex: 1;

            span {
                display: block;
                margin-bottom: 12px;
                i {
                    font-style: normal;
                    display: inline-block;
                    width: 45px;
                }
            }
            span:last-child {
                margin-bottom: 0;
            }
            em {
                display: inline-block;
                padding: 0 8px;
                height: 30px;
                border: 1px solid #e5e5e5;
                border-radius: 2px;
                font-size: 14px;
                font-weight: 400;
                text-align: center;
                line-height: 30px;
                margin-right: 12px;
                font-style: normal;
            }
            em.blue {
                border-color: #2370eb;
                color: #2370eb;
            }
            em.orange {
                border-color: #fa8c15;
                color: #fa8c15;
            }
        }
        > div {
            width: 48%;
            display: flex;
        }
    }
    .btn {
        span {
            display: inline-block;
            width: 60px;
            padding: 2px 0;
            text-align: center;
            border-radius: 1px;
            border: 1px solid #2370eb;
            color: #2370eb;
        }
    }
}
</style>
