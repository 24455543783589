<template>
    <div class="customer_info" v-if="customerDO">
        <div class="customer_info_item">
            <label>渠道分类</label>
            <p>销售线索</p>
        </div>
        <div class="customer_info_item">
            <label>客户</label>
            <p>{{ customerDO.companyName }}</p>
        </div>
        <div class="customer_info_item">
            <label>客户类型</label>
            <p>{{ customerDO.enterpriseType == 1 ? '企业' : '个人' }}</p>
        </div>
        <div class="customer_info_item">
            <label>客户标签</label>
            <p>
                <em
                    v-for="item in customerTagList"
                    :key="item.id"
                    :class="item.category == 0 ? 'blue' : 'orange'"
                >
                    {{ item.tagName }}
                </em>
            </p>
        </div>
        <div class="customer_info_item">
            <label>域名</label>
            <p>{{ customerDO.companyDomain || '- -' }}</p>
        </div>
        <div class="customer_info_item">
            <label>关联域名</label>
            <p
                v-if="
                    customerDO.customerDomainList &&
                    customerDO.customerDomainList.length > 0
                "
            >
                <span
                    v-if="item.adminId == adminId"
                    style="width: 100%"
                    v-for="item in customerDO.customerDomainList"
                    :key="item.id"
                >
                    <i style="width: 100%">{{ item.domain }}</i>
                </span>
            </p>
            <p v-else>- -</p>
        </div>
        <div class="customer_info_item">
            <label>联系方式</label>
            <p
                v-if="
                    customerDO.customerMainContactList &&
                    customerDO.customerMainContactList.length > 0
                "
            >
                <span
                    v-for="item in customerDO.customerMainContactList"
                    :key="item.id"
                    v-if="item.adminId == adminId"
                >
                    <i>{{ $tableDataHandle.contactWay(item) }}</i
                    >&nbsp;&nbsp; {{ item.contactContent }}&nbsp;&nbsp;{{
                        item.contactName
                    }}
                </span>
            </p>
            <p v-else>- -</p>
        </div>
        <div class="customer_info_item">
            <label>预计用户数</label>
            <p>
                {{
                    customerDO.number
                        ? $MailStatus.echoEstimateNumber(customerDO.number) +
                          '人'
                        : '- -'
                }}
            </p>
        </div>
        <div class="customer_info_item">
            <label>预计年限</label>
            <p>
                {{
                    customerDO.term
                        ? $MailStatus.echoEstimateTerm(customerDO.term) + '年'
                        : '- -'
                }}
            </p>
        </div>

        <div class="customer_info_item">
            <label>到期时间</label>
            <p>{{ customerDO.customerExpireTime || '- -' }}</p>
        </div>
        <div class="customer_info_item">
            <label>原邮箱品牌</label>
            <p>{{ $tableDataHandle.primaryEmailBrand(customerDO) }}</p>
        </div>

        <div class="customer_info_item">
            <label>所在地</label>
            <p>
                {{ $address.address(customerDO.provinceId, customerDO.cityId) }}
            </p>
        </div>
        <div class="customer_info_item">
            <label>地址</label>
            <p>{{ customerDO.address || '- -' }}</p>
        </div>
        <div class="customer_info_item">
            <label>联系备注</label>
            <p>{{ customerDO.contactRemark || '- -' }}</p>
        </div>

        <div class="customer_info_item">
            <label>备注</label>
            <p>{{ customerDO.remark || '- -' }}</p>
        </div>
    </div>
</template>

<script>
import { relationList } from '@/api/user/label.js';
export default {
    name: '',
    data() {
        return {
            customerTagList: [],
            customerDO: {},
            btnP: {},
            adminId: sessionStorage.getItem('adminId'),
        };
    },
    watch: {},
    components: {},
    created() {},
    mounted() {},
    methods: {
        getData(data, btnP) {
            this.btnP = btnP;
            this.customerDO = data;
            let arr = [];
            if (
                this.customerDO.customerContactList &&
                this.customerDO.customerContactList.length > 0
            ) {
                this.customerDO.customerContactList.forEach((item) => {
                    item.customerContactWayDOList.forEach((itm) => {
                        itm.contactName = item.contactName;
                        if (
                            this.customerDO.customerMainContactList &&
                            this.customerDO.customerMainContactList.length > 0
                        ) {
                            this.customerDO.customerMainContactList.forEach(
                                (i) => {
                                    if (i.contactName != itm.contactName) {
                                        arr.push(itm);
                                        // = this.customerDO.customerMainContactList.concat(
                                        //     itm
                                        // );
                                    }
                                }
                            );
                        }
                    });
                });
                arr = this.norepeat(arr);
            }
            this.customerDO.customerMainContactList =
                this.customerDO.customerMainContactList.concat(arr);
            this.relationList(data.id);
        },
        norepeat(arr) {
            for (var i = 0; i < arr.length; i++) {
                for (var j = i + 1; j < arr.length; ) {
                    if (
                        arr[i].contactName == arr[j].contactName ||
                        arr[i].contactContent == arr[j].contactContent
                    ) {
                        arr.splice(j, 1);
                        j--;
                    }
                    j++;
                }
            }
            return arr;
        },
        // 获取所属标签
        relationList(id) {
            let data = {
                param: {
                    bandingTableType: 2,
                    bandingId: id,
                },
            };
            if (this.btnP.searchUser) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            relationList(data).then((res) => {
                if (res.code == 200) {
                    this.customerTagList = res.data.list;
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.customer_info {
    font-size: 14px;
    margin-bottom: 30px;
    color: #333;
    .customer_info_item:first-child {
        position: relative;
    }
    // .customer_info_item:first-child:after {
    //     content: '';
    //     position: absolute;
    //     left: -8px;
    //     top: 50%;
    //     transform: translateY(-50%);
    //     width: 3px;
    //     height: 14px;
    //     background: #2370eb;
    //     border-radius: 1px;
    // }
    .customer_info_item {
        display: flex;

        margin-bottom: 18px;
        justify-content: space-between;
        label {
            width: 75px;
            font-weight: 600;
        }
        p {
            flex: 1;
            span {
                display: block;
                line-height: 1.6;
                i {
                    font-style: normal;
                    display: inline-block;
                    width: 45px;
                }
            }
            em {
                display: inline-block;
                padding: 0 8px;
                height: 30px;
                border: 1px solid #e5e5e5;
                border-radius: 2px;
                font-size: 14px;
                font-weight: 400;
                text-align: center;
                line-height: 30px;
                margin-right: 12px;
                font-style: normal;
            }
            em.blue {
                border-color: #2370eb;
                color: #2370eb;
            }
            em.orange {
                border-color: #fa8c15;
                color: #fa8c15;
            }
        }
        > div {
            width: 48%;
            display: flex;
        }
    }
}
</style>
