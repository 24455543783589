<template>
    <div class="sync_dealings">
        <el-checkbox-group v-model="intercourseList">
            <div
                class="dealings_item"
                v-for="item in customerDealingsList"
                :key="item.id"
            >
                <el-form label-position="left" label-width="70px" size="mini">
                    <el-form-item label="往来时间">
                        {{ item.intercourseTime || '- -' }}
                        <el-checkbox :label="item">同步</el-checkbox>
                    </el-form-item>
                    <el-form-item label="往来标题">
                        {{ item.intercourseTitle || '- -' }}
                    </el-form-item>
                    <el-form-item label="跟进人">
                        {{ item.adminName || '- -' }}
                    </el-form-item>
                    <el-form-item label="联系方式">
                        {{
                            item.contactWay == 1
                                ? '电话'
                                : item.contactWay == 2
                                ? '微信'
                                : item.contactWay == 3
                                ? '邮箱'
                                : item.contactWay == 4
                                ? 'QQ'
                                : item.contactWay == 5
                                ? '短信'
                                : item.contactWay == 6
                                ? '拜访'
                                : item.contactWay == 7
                                ? '其他'
                                : item.contactWay == 8
                                ? '官网表单'
                                : item.contactWay == 9
                                ? '座机'
                                : '- -'
                        }}
                    </el-form-item>
                    <el-form-item label="往来内容">
                        {{ item.intercourseContent || '- -' }}
                    </el-form-item>
                </el-form>
            </div>
        </el-checkbox-group>
        <div class="footer_btn">
            <el-button size="mini" round @click="onClose">取 消</el-button>
            <el-button type="primary" size="mini" round @click="onSave"
                >保 存</el-button
            >
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    props: {},
    data() {
        return {
            intercourseList: [],
            customerDealingsList: [{}],
        };
    },
    components: {},
    created() {},
    // mounted() {
    //   this.intercourseList = [];
    // },
    // activated() {
    //   this.intercourseList = [];
    // },
    // beforeUpdate() {
    //   // console.log(456);
    // },
    methods: {
        onClose() {
            this.intercourseList = [];
            this.$emit('close');
        },
        onSave() {
            this.$emit('onSave', this.intercourseList);
            this.intercourseList = [];
        },
    },
    // beforeDestroy() {
    //   // console.log(123);
    // },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.sync_dealings {
    height: 100%;
    display: flex;
    flex-direction: column;
    .el-checkbox-group {
        flex: 1;
        overflow-y: auto;
    }
    .dealings_item {
        position: relative;
        padding: 14px 57px 5px 32px;
        border-bottom: 1px dashed #eee;
        .el-form-item:first-child {
            position: relative;
        }
        .el-form-item:first-child:after {
            content: '';
            position: absolute;
            left: -15px;
            top: 50%;
            transform: translateY(-50%);
            width: 6px;
            height: 6px;
            background: #2370eb;
            border-radius: 50%;
        }
        .el-form-item--mini.el-form-item {
            margin-bottom: 10px;
        }
        /deep/ .el-form-item__label {
            color: #666 !important;
        }
        .el-checkbox {
            position: absolute;
            right: -30px;
            top: 0px;
        }
    }
    .dealings_item:last-child {
        border-bottom: none;
    }
    .footer_btn {
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: right;
        border-top: 1px solid #e5e5e5;
        background-color: #fff;
        .el-button--primary {
            background-color: #2370eb;
            border-color: #2370eb;
        }
        button:last-child {
            margin-right: 30px;
        }
    }
}
</style>
