<template>
    <div class="add_clue">
        <div>
            <el-form label-width="80px" label-position="left" size="small">
                <div class="add_clue_top_box">
                    <el-form-item label="线索产品" required>
                        <el-cascader
                            size="small"
                            ref="cascader"
                            v-model="clueDO.productClue"
                            :options="productListData"
                            :style="{ width: '270px' }"
                            :show-all-levels="false"
                            placeholder="请选择线索产品"
                            :props="{
                                expandTrigger: 'hover',
                                emitPath: false,
                                value: 'id',
                                label: 'productName',
                                children: 'children',
                            }"
                        >
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label="同步客户往来">
                        <div class="info" @click="showDrawerDealings">
                            已同步{{ intercourseList.length }}条往来记录
                            <el-button
                                type="primary"
                                class="el-icon-arrow-right"
                                size="mini"
                            ></el-button>
                        </div>
                    </el-form-item>
                    <el-form-item label="需求" required>
                        <el-input
                            type="textarea"
                            style="width: 270px"
                            v-model="clueDO.remark"
                            placeholder="请输入"
                            rows="6"
                        ></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <div class="public_info">
                <CustomerInfo ref="customerInfo"></CustomerInfo>
                <!-- <div
                    v-for="item in customerDO.customerContactList"
                    :key="item.id"
                >
                    <ConactsInfo
                        v-if="customerDO.id"
                        :customerContactList="item"
                    ></ConactsInfo>
                </div> -->
            </div>
        </div>
        <div class="footer_btn">
            <el-button size="mini" round @click="onClose">取 消</el-button>
            <el-button type="primary" size="mini" round @click="onSubmit"
                >生成线索</el-button
            >
        </div>
        <!-- 同步往来记录 -->
        <el-drawer
            :visible.sync="drawerDealings"
            :direction="direction"
            :before-close="handleClose"
            size="480px"
            :append-to-body="true"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    同步往来记录
                </div>
            </template>
            <SyncDealings
                @close="handleClose"
                @onSave="onSave"
                ref="syncDealings"
            />
        </el-drawer>
    </div>
</template>

<script>
import { addClue } from '@/api/user/clue.js';
import { dealingsList } from '@/api/user/custDealings.js';
import CustomerInfo from './components/customerInfo.vue';
import ConactsInfo from './components/conactsInfo.vue';
import SyncDealings from './components/syncDealings.vue';
import { productList } from '@/api/product/product.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            clueDO: {},
            intercourseList: [],
            customerDO: {},
            direction: 'rtl',
            drawerDealings: false,
            customerDealingsList: [],
            btnP: {},
            productListData: [],
        };
    },
    components: {
        CustomerInfo,
        ConactsInfo,
        SyncDealings,
    },
    created() {},

    mounted() {
        // this.customerDealings(243);
    },
    methods: {
        getData(data, btnP = {}) {
            this.clueDO = {};
            this.btnP = btnP;
            console.log(data);
            this.customerDO = data;
            this.customerDealings(data.id);
            this.$refs.customerInfo.getData(data, btnP);
            let data2 = {
                param: {
                    status: 1,
                },
                pageSize: 0,
                pageNum: 0,
            };
            productList(data2).then((res) => {
                this.productListData = this.$Tree.treeDataTranslate(
                    res.data.list,
                    'id',
                    'parentId',
                    false
                );
            });
        },
        // 生成线索
        onSubmit() {
            if (!this.clueDO.productClue) {
                this.$message.error('请选择线索产品');
                return;
            }
            if (!this.clueDO.remark) {
                this.$message.error('请输入需求');
                return;
            }
            let data = {
                param: {
                    customerId: this.customerDO.id,
                    productClue: this.clueDO.productClue,
                    remark: this.clueDO.remark,
                    entryType: 1,
                    sourceCategory: 2,
                    companyName: this.customerDO.companyName,
                    companyType: this.customerDO.customerType,
                    companyDomain: this.customerDO.companyDomain,
                    estimateNumber: this.customerDO.number,
                    estimateTerm: this.customerDO.term,
                    primaryEmailBrand: this.customerDO.primaryEmailBrand,
                    provinceId: this.customerDO.provinceId,
                    cityId: this.customerDO.cityId,
                    address: this.customerDO.address,
                    contactRemark: this.customerDO.contactRemark,
                    lineDomainDOList: this.customerDO.customerDomainList,
                    lineContactDOList:
                        this.$refs.customerInfo.$data.customerDO
                            .customerMainContactList,
                    lineIntercourseList: this.intercourseList,
                },
            };
            // console.log(data);
            this.$emit('onSubmit', data);
        },
        // 客户往来列表
        customerDealings(id) {
            let data = {
                param: {
                    customerId: id,
                },
            };
            if (this.btnP.searchUser) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
            } else {
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            dealingsList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.customerDealingsList = res.data.list;
                }
            });
        },
        showDrawerDealings() {
            this.drawerDealings = true;
            setTimeout(() => {
                this.$refs.syncDealings.$data.customerDealingsList =
                    this.customerDealingsList;
            }, 0);
        },
        onClose() {
            this.clueDO = {};
            this.intercourseList = [];
            this.$emit('close');
        },
        handleClose() {
            this.drawerDealings = false;
        },
        onSave(data) {
            // console.log(data);

            this.drawerDealings = false;
            this.intercourseList = data;
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.add_clue {
    color: #333333;
    font-size: 14px;
    overflow: hidden;
    display: flex;
    height: 100%;
    flex-direction: column;
    > div:first-child {
        flex: 1;
        overflow-y: auto;
    }
    .add_clue_top_box {
        border-bottom: 1px dashed #eeeeee;
        margin-top: 15px;
        padding-left: 42px;
        .el-form-item {
            margin-bottom: 0;
        }
    }
    .el-input {
        width: 270px;
    }
    .alone_info {
        padding-left: 42px;
        padding-top: 12px;
        border-bottom: 1px dashed #eeeeee;
        > p {
            color: #2370eb;
            margin-bottom: 20px;
        }

        /deep/ .el-textarea__inner {
            resize: none;
            width: 270px;
        }
    }
    .info {
        width: 270px;
        height: 32px;
        line-height: 32px;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        position: relative;
        padding-left: 10px;
        cursor: pointer;
        .el-button {
            position: absolute;
            right: 0;
            top: 0;
            width: 32px;
            height: 32px;
            padding: 0;
            box-sizing: border-box;
            background-color: transparent;
            border-color: transparent;
            border-left: 1px solid #e5e5e5;
            border-radius: 0;
            color: #2370eb;
            font-weight: 600;
        }
    }
    .public_info {
        padding: 16px 42px 0px;
        > p {
            color: #2370eb;
            margin-bottom: 20px;
        }
    }
    .footer_btn {
        width: 100%;
        height: 50px;
        line-height: 50px;
        text-align: right;
        border-top: 1px solid #e5e5e5;
        background-color: #fff;
        .el-button--primary {
            background-color: #2370eb;
            border-color: #2370eb;
        }
        button:last-child {
            margin-right: 30px;
        }
    }
}
</style>
